import React from 'react';
import PageLayout from '../components/PageLayout';
import {graphql} from 'gatsby';
import {useTranslation} from 'react-i18next';
import {Link} from 'gatsby-plugin-react-i18next';
import Reviews from '../components/Reviews';
import {StaticImage} from 'gatsby-plugin-image';
import {FaMoneyBillAlt, FaQuestion} from 'react-icons/fa';
import {IoMdHeart} from 'react-icons/io';
import {MdOutlineFamilyRestroom, MdWork} from 'react-icons/md';
import {IoCheckmarkSharp} from 'react-icons/io5';
import {BiChevronRight} from 'react-icons/bi';
import {GiCrystalBall} from 'react-icons/gi';
import {remove} from '../services/storage';
import {Helmet} from '../components/Helmet';
import {pushEvent} from '../services/gtmManager';

const IndexPage = () => {
  const {t} = useTranslation();

  const onQuizStartClick = () => {
    remove('quiz');
    pushEvent('web', {action: 'landingQuizStart'})
  }

  return (
    <>
      <Helmet title={t('landing.meta_title')} description={t('landing.meta_description')}>
        <meta name='verify-paysera' content='7df759b8d02270c609144e8212728050' />
      </Helmet>
      <PageLayout className='pt-0 text-lg'>
        <header className='bg-landing-header bg-no-repeat bg-cover bg-center'>
          <div className='bg-black bg-opacity-60 h-full w-full py-20 flex flex-col justify-center lg:min-h-[60vh] min-h-[75vh]'>
            <h1 className='text-center text-4xl font-medium mb-2'>{t('landing.header1')}</h1>
            <h2 className='text-center text-7xl font-bold mb-6'>{t('landing.header2')}</h2>
            <p className='text-center text-2xl mb-8'>{t('landing.subheader')}</p>
            <div className='text-center'>
              <Link className='btn btn-success btn-lg text-white' to='/tarot-reading' onClick={onQuizStartClick}>
                {t('landing.start_quiz_now')}
                <GiCrystalBall className='ml-2 ' size={32} />
              </Link>
            </div>
          </div>
        </header>
        <section className='bg-white text-black py-12'>
          <div className='container lg:flex lg:justify-center lg:gap-6'>
            <div className='lg:w-2/5'>
              <h3 className='text-4xl font-bold mb-6 lg:text-left text-center'>
                {t('landing.personal_fortune.header')}
              </h3>
              <p className='mb-4'>{t('landing.personal_fortune.text1')}</p>
              <p className='mb-4'>{t('landing.personal_fortune.text2')}</p>
              <Link
                className='font-bold underline flex items-center'
                to='/tarot-reading'
                onClick={onQuizStartClick}
              >
                {t('landing.start_quiz_now')}
                <BiChevronRight size={24} />
              </Link>
            </div>
            <div className='lg:block hidden'>
              <StaticImage
                imgClassName='rounded-xl'
                width={500}
                src='../images/tarot-cards.png'
                alt='Tarot cards on the table'
              />
            </div>
          </div>
        </section>
        <section className='bg-[#F0F0F0] text-black pt-12'>
          <div className='container flex lg:flex-row flex-col justify-center gap-6'>
            <div>
              <StaticImage
                imgClassName='rounded-xl'
                width={500}
                src='../images/tarot-cards-hand.png'
                alt='Tarot cards on the table'
              />
            </div>
            <div className='lg:w-2/5'>
              <h3 className='text-4xl font-bold mb-6 lg:text-left text-center'>{t('landing.what_is_tarot.header')}</h3>
              <p className='mb-4'>{t('landing.what_is_tarot.text1')}</p>
              <p className='mb-4'>{t('landing.what_is_tarot.text2')}</p>
              <Link
                className='font-bold underline flex items-center'
                to='/tarot-reading'
                onClick={onQuizStartClick}
              >
                {t('landing.start_quiz')}
                <BiChevronRight size={24} />
              </Link>
            </div>
          </div>
        </section>
        <section className='bg-[#F0F0F0] text-black py-12'>
          <div className='container flex flex-col items-center'>
            <h3 className='text-4xl font-bold mb-6 lg:text-left text-center'>{t('landing.questions.header')}</h3>
            <div>
              <div className='flex gap-2 items-center mb-3'>
                <div>
                  <FaMoneyBillAlt size={32} />
                </div>
                <div>{t('landing.questions.text1')}</div>
              </div>
              <div className='flex gap-2 mb-3'>
                <div>
                  <IoMdHeart size={32} />
                </div>
                <div>{t('landing.questions.text2')}</div>
              </div>
              <div className='flex gap-2 mb-3'>
                <div>
                  <MdWork size={32} />
                </div>
                <div>{t('landing.questions.text3')}</div>
              </div>
              <div className='flex gap-2 mb-3'>
                <div>
                  <MdOutlineFamilyRestroom size={32} />
                </div>
                <div>{t('landing.questions.text4')}</div>
              </div>
              <div className='flex gap-2 mb-3'>
                <div>
                  <FaQuestion size={32} />
                </div>
                <div>{t('landing.questions.text5')}</div>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-white text-black py-12'>
          <div className='container flex lg:flex-row flex-col justify-center gap-6'>
            <div className='lg:order-1 order-2 lg:w-2/5'>
              <h3 className='text-4xl font-bold mb-6 lg:text-left text-center'>
                {t('landing.whom_tarot_fits.header')}
              </h3>
              <p className='mb-4'>{t('landing.whom_tarot_fits.text')}</p>
              <Link
                className='font-bold underline flex items-center'
                to='/tarot-reading'
                onClick={onQuizStartClick}
              >
                {t('landing.start_quiz')}
                <BiChevronRight size={24} />
              </Link>
            </div>
            <div className='lg:order-2 order-1'>
              <StaticImage
                imgClassName='rounded-xl'
                width={500}
                src='../images/three-tarot-cards-hand.png'
                alt='Tarot cards on the table'
              />
            </div>
          </div>
        </section>
        <section className='bg-[#F0F0F0] text-black py-12'>
          <div className='container flex flex-col items-center'>
            <h3 className='text-4xl font-bold mb-6 lg:text-left text-center'>{t('landing.fortune_offer.header')}</h3>
            <div>
              <div className='flex gap-2 items-center mb-2'>
                <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                <div>{t('landing.fortune_offer.text1')}</div>
              </div>
              <div className='flex gap-2 items-center mb-2'>
                <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                <div>{t('landing.fortune_offer.text2')}</div>
              </div>
              <div className='flex gap-2 items-center mb-2'>
                <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                <div>{t('landing.fortune_offer.text3')}</div>
              </div>
              <div className='flex gap-2 items-center mb-2'>
                <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                <div>{t('landing.fortune_offer.text4')}</div>
              </div>
              <div className='flex gap-2 items-center mb-2'>
                <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                <div>{t('landing.fortune_offer.text5')}</div>
              </div>
              {/*<div className='flex gap-2 items-center'>*/}
              {/*  <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />*/}
              {/*  <div>{t('landing.fortune_offer.text6')}</div>*/}
              {/*</div>*/}
            </div>
          </div>
        </section>
        <section className='bg-white text-black py-12'>
          <div className='container flex flex-col items-center max-w-3xl'>
            <h3 className='text-4xl font-bold mb-6 lg:text-left text-center'>{t('landing.reviews.header')}</h3>
            <div className='mb-6'>
              <Reviews />
            </div>
            <div className='text-center'>
              <Link className='btn btn-secondary btn-lg text-white' to='/tarot-reading' onClick={onQuizStartClick}>
                {t('landing.start_quiz_now')}
                <GiCrystalBall className='ml-2 ' size={32} />
              </Link>
            </div>
          </div>
        </section>
      </PageLayout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["index"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
