import React, {useMemo} from 'react';
import {IoStarSharp} from 'react-icons/io5';
import {useTranslation} from 'react-i18next';
import {graphql, useStaticQuery} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

const Reviews = () => {
  const {t} = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      reviewImages: allFile(filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "success-stories"}}) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(width: 48, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  `);

  const imageMap = useMemo(() => {
    const result: any = {};

    for (const node of data.reviewImages.nodes) {
      result[node.name] = node.childImageSharp;
    }

    return result;
  }, [data.reviewImages.nodes]);

  return (
    <>
      {[...Array(8)].map((value, index) => (
        <div key={index} className='mb-4'>
          <div className='mb-2 flex gap-2'>
            <div>
              <GatsbyImage
                className='rounded-full'
                image={getImage(imageMap[t(`reviews.img_${index + 1}`)])!}
                alt='Face'
              />
            </div>
            <div>
              <div>{t(`reviews.name_${index + 1}`)}</div>
              <div className='flex gap-1'>
                {[...Array(index === 3 ? 4 : index === 7 ? 3 : 5)].map((value, index) => (
                  <IoStarSharp key={index} size={18} className='text-yellow-400' />
                ))}
              </div>
            </div>
          </div>
          <div>{t(`reviews.review_${index + 1}`)}</div>
        </div>
      ))}
    </>
  );
};

export default Reviews;
